import styled from 'styled-components';

const PeezzyStyle = styled.div`
	&.peezzyCont {
		position: absolute;
		top: 0;
		right: 0;
		margin: auto;
		bottom: 0;
		width: 50%;
		float: right;

		.fillWhite {
			fill: var(--color1);
		}

		.fillBlack {
			fill: var(--color2);
		}

		.opacity {
			opacity: 0;
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			right: initial;
			left: 0;
		}
	}
`;

export default PeezzyStyle;
