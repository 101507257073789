import { memo as Memo } from 'react';

//* Components
import CustomLottie from '../../CustomLottie';

//* JSONs
import ziggyJSON from './ziggyJSON.json';

//* Styles
import ZiggyStyle from './style';

const Ziggy = Memo((props) => {
	return (
		<ZiggyStyle className={'ziggy'}>
			<CustomLottie
				isStopped={!props.play}
				animData={ziggyJSON}
			/>
		</ZiggyStyle>
	);
});

export default Ziggy;
