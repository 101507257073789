
import Lottie from 'react-lottie';

const CustomLottie = ({ animData, width = '100%', height = 'auto', isStopped, isPaused = false }) => {
    return (
        <Lottie
            width={width}
            height={height}
            isPaused={isPaused}
            isStopped={isStopped}
            options={{
                loop: true,
                autoplay: true,
                animationData: animData,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                },
            }}
        />
    );
};

export default CustomLottie;
