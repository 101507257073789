import { memo as Memo, useEffect, useMemo, useRef } from 'react';
import { gsap, TimelineMax, Power0 } from 'gsap';
import { MorphSVGPlugin } from 'gsap/dist/MorphSVGPlugin';

gsap.registerPlugin(MorphSVGPlugin);

const Rolly = Memo(({ translateX, minusPosition, rotationCount = 3, time = 2, ...props }) => {
	//! Refs
	const contRef = useRef();
	const bodyRef = useRef();

	//! Vars
	const rollyAnim = useMemo(() => new TimelineMax({ yoyo: false, repeat: 0 }), []);
	const bodyR = useMemo(() => 66, []);
	const bodyL = useMemo(() => 2 * Math.PI * bodyR, [bodyR]);
	const bodyDefaultX = useMemo(() => minusPosition || 2.3 * bodyL, [minusPosition, bodyR]);
	const circleLength = useMemo(() => (translateX ? translateX : bodyL * rotationCount), [rotationCount, translateX, bodyL]);
	const count = useMemo(() => (translateX ? (circleLength / bodyL) * 360 : 360 * rotationCount), [rotationCount, translateX, bodyL, circleLength]);

	//! Animation
	useEffect(() => {
		let newTime = time;
		rollyAnim
			//! Default Positions
			.set('.ro-ex, .ro-rf, .ro-lf, .ro-lh, .ro-rh', { opacity: 0 })
			.set('.ro-body', { transformOrigin: '102 50%', rotation: circleLength - bodyL / 4, translateX: bodyDefaultX })
			//! Rotation Animation
			.to('.ro-body', time, { transformOrigin: '102 50%', translateX: -bodyL / 4, rotation: -5, ease: Power0.easeOut })
			//! Left Hand Animation
			.to('.ro-lh', 0.5, { morphSVG: { shape: '.ro-lhh', type: 'linear' }, ease: Power0.easeNone }, time)
			//! Step 1 when Rotation Ended
			.to('.ro-body-inner', 0.5, { transformOrigin: '102 50%', translateX: -10, rotation: -10, ease: Power0.easeInOut }, time)
			.to('.ro-lf', 0.5, { svgOrigin: '56 124', rotation: -10, ease: Power0.easeInOut }, time)
			.to('.ro-rf', 0.5, { svgOrigin: '123 131', rotation: -5, ease: Power0.easeInOut }, time)
			//! Step 2 when Rotation Ended
			.to('.ro-body-inner', 0.5, { transformOrigin: '102 50%', translateX: 5, rotation: 5, ease: Power0.easeInOut }, time + 0.5)
			.to('.ro-lf', 0.5, { svgOrigin: '56 124', rotation: 0, ease: Power0.easeInOut }, time + 0.5)
			.to('.ro-rf', 0.5, { svgOrigin: '123 131', rotation: -10, ease: Power0.easeInOut }, time + 0.5)
			//! Step 3 when Rotation Ended
			.to('.ro-body-inner', 0.5, { transformOrigin: '102 50%', translateX: 0, rotation: 0, ease: Power0.easeInOut }, time + 0.5 * 2)
			.to('.ro-rf', 0.5, { svgOrigin: '123 131', rotation: 0, ease: Power0.easeInOut }, time + 0.5 * 2)
			//! Step 4 when Rotation Ended
			.set('.ro-ey', { opacity: 0 }, time + 0.5 * 2 + 0.1)
			.set('.ro-ex', { opacity: 1 }, time + 0.5 * 2 + 0.1)
			//! Step 5 when Rotation Ended
			.set('.ro-ex', { opacity: 0 }, time + 0.5 * 2 + 0.7)
			.set('.ro-ey', { opacity: 1 }, time + 0.5 * 2 + 0.7)
			//! Step 6 when Rotation Ended
			.set('.ro-ey', { opacity: 0 }, time + 0.5 * 2 + 1.3)
			.set('.ro-ex', { opacity: 1 }, time + 0.5 * 2 + 1.3)
			//! Step 7 when Rotation Ended
			.set('.ro-ex', { opacity: 0 }, time + 0.5 * 2 + 1.9)
			.set('.ro-ey', { opacity: 1 }, time + 0.5 * 2 + 1.9)
			.eventCallback('onUpdate', () => {
				const rot = parseInt(gsap.getProperty('.ro-body', 'rotation').toFixed());
				if (rot < 360) {
					//! Body Parts Animation when Rotation Ending
					rot > 60 && rollyAnim.set('.ro-rh', { rotate: 180 - rot, opacity: 1 }, 0);
					rot < 100 && rollyAnim.set('.ro-lh', { svgOrigin: '40 90', rotation: rot / 5 }, 0);
					rot < 150 && rollyAnim.set('.ro-lh', { opacity: 1 }, 0);
					rot < 60 && rot > 2 && rollyAnim.set('.ro-rf', { svgOrigin: '123 131', rotation: -rot, opacity: 1 }, 0);
					rot < 160 && rot > 2 && rollyAnim.set('.ro-lf', { svgOrigin: '56 124', rotation: -rot / 5, opacity: 1 }, 0);
					rot < 60 && rollyAnim.set('.ro-rh', { svgOrigin: '158 98', rotation: -rot * 2, opacity: 1 }, 0);
				}
			});

		return () => {
			rollyAnim.kill();
		};
	}, []);

	return (
		<svg
			className={`rolly`}
			ref={contRef}
			viewBox='0 0 182 162'>
			<g
				ref={bodyRef}
				className={`ro-body`}>
				<g className={`ro-body-inner`}>
					<circle
						className={`ro-circle fillBalck`}
						cx='100'
						cy='83'
						r={bodyR}
					/>
					<g className='ro-ey'>
						<path
							className={`ro-eyl st1`}
							d='M81.9,69.2l-0.6,3.5l-12.6-2l0.6-3.5L81.9,69.2z'
						/>
						<path
							className={`ro-eyr st1`}
							d='M115.7,74.7l-0.6,3.5l-12.6-2l0.6-3.5L115.7,74.7z'
						/>
					</g>
					<g className='ro-ex'>
						<path
							className={`ro-exl st1`}
							d='M77.8,79.2l-2.9-5.9l-4,4.8l-4.6-0.7l6.8-7.7l-4.3-8.7l4.7,0.8l2.8,5.8l4-4.7l4.6,0.7L78,70.9l4.4,9L77.8,79.2z'
						/>
						<path
							className={`ro-exr st1`}
							d='M111.6,84.6l-2.9-5.9l-4,4.8l-4.6-0.7l6.8-7.7l-4.3-8.7l4.7,0.8l2.8,5.8l4-4.7l4.6,0.7l-6.9,7.4l4.4,9L111.6,84.6z'
						/>
					</g>

					<path
						className={`ro-rh st2`}
						d='M158.3,98.2c0,0,21.1,9.3,22.3,31.7'
					/>
					<path
						className={`ro-lhh st3`}
						d='M39.8,85.1c0,0-22.9,2.8-30.6,23.9'
					/>
					<path
						className={`ro-lh st2`}
						d='M40.4,70.6c0,0-16.5,16.1-37.8,8.8'
					/>
				</g>
				<path
					className={`ro-rf st2`}
					d='M123.7,131.5c0,0,34.3,38.9,50.5,26.3'
				/>
				<path
					className={`ro-lf st2`}
					d='M56.6,124.5c0,0-42.2,30.1-55.2,14.1'
				/>
			</g>
		</svg>
	);
});

export default Rolly;
