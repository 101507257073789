import styled from 'styled-components';

const PageTopSectionStyle = styled.div`
	&.ContainerWrap {
		margin-bottom: var(--titleToContent);

		&.underline {
			border-bottom: 2px solid var(--color2);
			padding-bottom: var(--sp8x);
		}

		.title {
			margin-top: calc(var(--titleToContent) / 2);
			margin-bottom: var(--titleToText);

			span {
				opacity: 0;
			}
		}

		.subtitle {
			max-width: 324px;
			margin-bottom: var(--textToDescription);
			white-space: pre-wrap;
			opacity: 0;
		}

		.description {
			max-width: 324px;
			white-space: pre-wrap;
			opacity: 0;
		}

		.servicesTop {
			--animationSize: 164px;
			--leftMarg: var(--sp8x);
			--bottomMarg: -18px;

			position: relative;

			.titleOther {
				letter-spacing: -1px;
				margin-top: calc(var(--titleToContent) / 2);
				margin-bottom: var(--titleToText);

				span:first-child {
					margin-left: var(--leftMarg);
					margin-bottom: var(--bottomMarg);
				}

				span {
					opacity: 0;
				}
			}
			.servicesTitle {
				position: relative;

				.servicesCounter {
					opacity: 0;
					position: absolute;
					top: 0;
					left: 0;
					transform: translateY(-65%);
				}
			}

			.servicesAnimationWrap {
				position: relative;
				width: 100%;
				height: var(--animationSize);
				right: 0;
				bottom: var(--sp3x);
				display: flex;
				justify-content: flex-end;
				padding-top: 50%;
				margin: var(--sp2x) 0 var(--sp5x);
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			.subtitle {
				max-width: 100%;
			}

			.description {
				&.long {
					max-width: 70%;
				}

				&.short {
					max-width: 77%;
				}
			}

			.servicesTop {
				--leftMarg: var(--sp15x);
				--bottomMarg: -48px;
				--animationSize: 140px;
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			.position_right {
				display: flex;
				justify-content: flex-end;
			}

			.servicesTop {
				.servicesTitle {
					.servicesCounter {
						transform: translateY(-50%);
					}
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			.servicesTop {
				.servicesAnimationWrap {
					position: absolute;
					padding-top: 25%;
					width: 20%;
					margin: 0;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			.subtitle {
				max-width: 86%;
			}

			.description {
				&.long {
					max-width: 70%;
				}

				&.short {
					max-width: 48%;
				}
			}

			.servicesTop {
				--leftMarg: var(--sp13x);
				--bottomMarg: -48px;
				--animationSize: 165px;
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
			.subtitle {
				max-width: 86%;
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
			.servicesTop {
				--leftMarg: var(--sp15x);
				--bottomMarg: -56px;
				--animationSize: 220px;
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
			.description {
				&.long {
					max-width: 68%;
				}

				&.short {
					max-width: 49%;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
			.description {
				&.long {
					max-width: 56%;
				}

				&.short {
					max-width: 49%;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
			.description {
				&.long {
					max-width: 61%;
				}

				&.short {
					max-width: 47%;
				}
			}

			.servicesTop {
				--leftMarg: var(--sp17x);
				--bottomMarg: -68px;
				--animationSize: 252px;
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
			&.underline {
				padding-bottom: var(--sp10x);
			}

			.subtitle {
				max-width: 90%;
			}

			.description {
				&.long {
					max-width: 59%;
				}

				&.short {
					max-width: 49%;
				}
			}

			.servicesTop {
				--leftMarg: var(--sp21x);
				--bottomMarg: -100px;
				--animationSize: 332px;
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
			&.underline {
				padding-bottom: var(--sp15x);
			}

			.subtitle {
				max-width: 90%;
			}

			.description {
				&.long {
					max-width: 51%;
				}

				&.short {
					max-width: 49%;
				}
			}

			.servicesTop {
				--leftMarg: var(--sp27x);
				--bottomMarg: -124px;
				--animationSize: 352px;
			}
		}
	}
`;

export default PageTopSectionStyle;
