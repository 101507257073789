import { memo as Memo, useEffect, useMemo, useCallback } from 'react';
import { gsap, TweenMax, Power0, TimelineMax } from 'gsap';
import { MorphSVGPlugin } from 'gsap/dist/MorphSVGPlugin';

gsap.registerPlugin(MorphSVGPlugin);

const Sharpy = Memo((props) => {
	const sharpyAnim = useMemo(() => new TimelineMax({ repeat: -1, yoyo: true, ease: Power0.easeNone }), []);

	const handAnim = useCallback(() => {
		TweenMax.to('.sha-rh', 0.3, { morphSVG: '.sha-rhh', translateX: '40', repeat: -1 }, 0);
		TweenMax.to('.sha-lh', 0.3, { morphSVG: '.sha-lhh', translateX: '-40', repeat: -1 }, 0);
	}, []);

	useEffect(() => {
		sharpyAnim.to('.sha-body-cont', 0.5, { translateY: '5' }, 0).to('.sha-rf', 0.5, { morphSVG: '.sha-rfh' }, 0).to('.sha-lf', 0.5, { morphSVG: '.sha-lfh' }, 0).set('.sha-ex', { opacity: 0 }, 0.25).set('.sha-ey', { opacity: 1 }, 0.25);

		handAnim();

		return () => {
			sharpyAnim.kill();
		};
	}, []);

	return (
		<svg
			className={`sharpy sharpy svg-max`}
			viewBox='0 0 273 228.08'>
			<g className='sha-body-cont'>
				<path
					className={`sha-body fillBlack`}
					d='M206.34,184.09H60.92C60.92,82.47,126,.09,206.34.09h0Z'
				/>

				<path
					className={`sha-ex sha-lex fillWhite`}
					d='M117.13,86.64l1.5,2.73-3.93,1.41,3.93,1.38L117,95l-3.21-2.7.69,4.17H111.4l.66-4.17L108.82,95l-1.65-2.85,3.93-1.41-3.87-1.38,1.53-2.73,3.3,2.64-.69-4.17h3.15l-.69,4.17Z'
				/>
				<path
					className={`sha-ex sha-rex fillWhite`}
					d='M164.77,86.64l1.5,2.73-3.93,1.41,3.93,1.38L164.68,95l-3.21-2.7.69,4.17H159l.66-4.17L156.46,95l-1.65-2.85,3.93-1.41-3.87-1.38,1.53-2.73,3.3,2.64L159,85.11h3.15l-.69,4.17Z'
				/>

				<path
					className={`sha-ey sha-ley fillWhite hideOpacity`}
					d='M119.42,88.8v3.51H106.67V88.8Z'
				/>
				<path
					className={`sha-ey sha-rey fillWhite hideOpacity`}
					d='M166.51,88.8v3.51H153.76V88.8Z'
				/>

				<path
					className={`sha-mouth fillWhite`}
					d='M132,100.83l5.67,16.77,5.7-16.77h4.53L140.35,122h-5.43l-7.5-21.18Z'
				/>

				<path
					className={`sha-lh hStyle`}
					d='M1.53,123.36c19.28,0,19.28,12,38.57,12s19.28-12,38.57-12,19.28,12,38.57,12c9.64,0,14.46-3,19.28-6'
				/>
				<path
					className={`sha-lhh hStyle hidden`}
					d='M1.53,135.38c19.28,0,19.28-12,38.57-12s19.28,12,38.57,12,19.28-12,38.57-12c9.64,0,14.46,3,19.28,6'
				/>

				<path
					className={`sha-rh hStyle`}
					d='M136.52,129.37c4.82-3,9.65-6,19.29-6,19.28,0,19.28,12,38.57,12s19.29-12,38.58-12,19.28,12,38.57,12'
				/>
				<path
					className={`sha-rhh hStyle hidden`}
					d='M136.52,129.37c4.82,3,9.65,6,19.29,6,19.28,0,19.28-12,38.57-12s19.29,12,38.58,12,19.28-12,38.57-12'
				/>
			</g>

			<path
				className={`sha-lf fStyle`}
				d='M148.48,176.18v37.71s-.79,12.71,14.05,12.76'
			/>
			<path
				className={`sha-lfh fStyle hidden`}
				d='M149.24,184.87l6.26,6.4c4.65,4.46,5.06,9.54.09,14.92l-3.67,4a9.3,9.3,0,0,0,.18,13c2.07,2,5.33,3.49,10.43,3.51'
			/>

			<path
				className={`sha-rf fStyle`}
				d='M124.38,176.18v37.71s.78,12.71-14.06,12.76'
			/>
			<path
				className={`sha-rfh fStyle hidden`}
				d='M123.61,184.87l-6.26,6.4c-4.64,4.46-5.06,9.54-.09,14.92l3.67,4a9.3,9.3,0,0,1-.18,13c-2.07,2-5.33,3.49-10.43,3.51'
			/>
		</svg>
	);
});

export default Sharpy;
