import { memo as Memo, useMemo, useEffect } from 'react';
import { TimelineMax, Power0 } from 'gsap';

//* Styles
import PeezzyStyle from './style';

const Peezzy = Memo((props) => {
	const peezzyAnim = useMemo(() => new TimelineMax({ repeat: -1, yoyo: true, repeatDelay: 0.5 }), []);

	useEffect(() => {
		peezzyAnim.set('.peezzy-hole', { rx: 0, ry: 0, rotationX: 100 }).set('.peezzy-group', { x: 160 });

		peezzyAnim.to('.peezzy-hole', 0.1, { opacity: 1 }).to('.peezzy-hole', 0.1, { rx: 15, ry: 121.4, opacity: 1, ease: Power0.easeIn }).to('.peezzy-hole', 0.1, { rx: 36, ry: 141.4, ease: Power0.easeIn }).to('.peezzy-hole', 0.1, { rx: 38.4, ease: Power0.easeIn }).to('.peezzy-group', 0.4, { x: 0, ease: Power0.easeIn }).to('.peezzy-eye-s-left', 0.001, { autoAlpha: 0, ease: Power0.easeIn }).to('.peezzy-eye-f-left', 0.001, { autoAlpha: 1, ease: Power0.easeIn }).to('.peezzy-eye-s-right', 0.001, { autoAlpha: 0, ease: Power0.easeIn, delay: 0.2 }, '-=0.005').to('.peezzy-eye-f-right', 0.001, { autoAlpha: 1, ease: Power0.easeIn }, '-=0.005');
	}, []);

	return (
		<PeezzyStyle className={'peezzyCont'}>
			<svg
				viewBox='0 0 182.08 282.79'
				className='svg-max'>
				<defs>
					<clipPath
						id='clip-3'
						clipPathUnits='userSpaceOnUse'>
						<rect
							x='0'
							y='80'
							width='140'
							height='160'
						/>
					</clipPath>
				</defs>

				<ellipse
					className={`peezzy-hole fillBlack`}
					cx='143.69'
					cy='141.4'
					rx='0'
					ry='0'
				/>
				<g
					className='peezzy'
					style={{ overflow: 'hidden' }}
					clipPath='url(#clip-3)'>
					<g
						className='peezzy-group'
						transform='matrix(1,0,0,1,160,0)'>
						<path
							className={`fillBlack`}
							d='M0,135.28l85.18,94a1.69,1.69,0,0,0,1.83.43L143.69,210V84.79C83.07,105.73-.49,134.74,0,135.28Z'
						/>
						<path
							className={`peezzy-eye-f peezzy-eye-f-left fillWhite opacity`}
							d='M83.16,149.06a7.58,7.58,0,0,1,4.2,1.77A9.13,9.13,0,0,1,90,154.94a7.91,7.91,0,0,1,.41,3.54,6.46,6.46,0,0,1-1.11,3A4.62,4.62,0,0,1,87,163.21a3.4,3.4,0,0,1-2.16.12,2.5,2.5,0,0,1-1.47-1.47,4.94,4.94,0,0,1-.94,2.25,4.17,4.17,0,0,1-2,1.36,3.91,3.91,0,0,1-2.18.15,3.83,3.83,0,0,1-1.86-1.05,5.27,5.27,0,0,1-1.25-2.07c-.09-.27-.15-.47-.19-.61a6.44,6.44,0,0,1-.08-3.11,5.87,5.87,0,0,1,3.92-4.29,4.75,4.75,0,0,1,3.52,0,3.92,3.92,0,0,1,2.16,2.47c.08.26.16.53.22.82l.38,2.06c0,.23.09.38.11.45a1.06,1.06,0,0,0,.48.67,1.05,1.05,0,0,0,.78,0,1.9,1.9,0,0,0,1-.89,3.62,3.62,0,0,0,.42-1.73,7.2,7.2,0,0,0-.43-2.38,7,7,0,0,0-2-3.06,5.79,5.79,0,0,0-3.17-1.4,9.25,9.25,0,0,0-4.15.54,9.66,9.66,0,0,0-4.24,2.69,7.68,7.68,0,0,0-1.89,4,9.73,9.73,0,0,0,.47,4.65,7,7,0,0,0,3.71,4.45,8.52,8.52,0,0,0,6.31-.15l.25,2.25a12.78,12.78,0,0,1-5.63.69,7.9,7.9,0,0,1-4.35-2A10.31,10.31,0,0,1,70,164.23a10.88,10.88,0,0,1-.39-6,10.69,10.69,0,0,1,2.85-5.1,13.49,13.49,0,0,1,5.36-3.3A12.24,12.24,0,0,1,83.16,149.06ZM81,156.89a2.41,2.41,0,0,0-1.87,0,2.68,2.68,0,0,0-1.26.89,3,3,0,0,0-.62,1.54,4.46,4.46,0,0,0,.25,2,2.6,2.6,0,0,0,1.25,1.58,2.24,2.24,0,0,0,1.8.09,2.66,2.66,0,0,0,1.26-.89,3.15,3.15,0,0,0,.65-1.56,4.25,4.25,0,0,0-.25-2.06A2.49,2.49,0,0,0,81,156.89Z'
						/>
						<path
							className={`peezzy-eye-f peezzy-eye-f-right fillWhite opacity`}
							d='M116.57,137.61a7.58,7.58,0,0,1,4.2,1.77,9.08,9.08,0,0,1,2.66,4.11,7.88,7.88,0,0,1,.41,3.53,6.38,6.38,0,0,1-1.11,3,4.58,4.58,0,0,1-2.29,1.74,3.4,3.4,0,0,1-2.16.12,2.5,2.5,0,0,1-1.47-1.47,5.1,5.1,0,0,1-.94,2.25,4.24,4.24,0,0,1-2,1.36,3.91,3.91,0,0,1-2.18.15,3.83,3.83,0,0,1-1.86-1,5.24,5.24,0,0,1-1.25-2.08c-.09-.26-.15-.46-.19-.6a6.44,6.44,0,0,1-.08-3.11,5.78,5.78,0,0,1,1.37-2.64,5.93,5.93,0,0,1,2.55-1.66,4.8,4.8,0,0,1,3.52,0,4,4,0,0,1,2.16,2.48c.08.26.15.53.21.82l.39,2.06a3.6,3.6,0,0,0,.11.45,1.09,1.09,0,0,0,.48.67,1.11,1.11,0,0,0,.78,0,2,2,0,0,0,1-.89,3.51,3.51,0,0,0,.41-1.73,6.72,6.72,0,0,0-.43-2.38,7,7,0,0,0-2-3.06,5.79,5.79,0,0,0-3.17-1.4,9,9,0,0,0-4.15.54,9.6,9.6,0,0,0-4.24,2.69,7.77,7.77,0,0,0-1.9,4,9.88,9.88,0,0,0,.48,4.65,7,7,0,0,0,3.71,4.44,8.56,8.56,0,0,0,6.31-.14l.25,2.25a12.78,12.78,0,0,1-5.63.69,7.9,7.9,0,0,1-4.35-1.95,10.22,10.22,0,0,1-2.78-4.44,10.51,10.51,0,0,1,2.46-11.15,13.42,13.42,0,0,1,5.36-3.3A12.24,12.24,0,0,1,116.57,137.61Zm-2.17,7.83a2.41,2.41,0,0,0-1.87,0,2.68,2.68,0,0,0-1.26.89,2.92,2.92,0,0,0-.62,1.54,4.46,4.46,0,0,0,.25,2,2.62,2.62,0,0,0,1.24,1.58,2.27,2.27,0,0,0,1.81.09,2.73,2.73,0,0,0,1.26-.89,3.15,3.15,0,0,0,.65-1.56,4.37,4.37,0,0,0-.25-2.06A2.49,2.49,0,0,0,114.4,145.44Z'
						/>
						<path
							className={`fillWhite`}
							d='M100.59,167.6a11.63,11.63,0,0,1,1.21.16,7.87,7.87,0,0,0,.93.12,1.52,1.52,0,0,0,.64-.07c.62-.22.86-.75.71-1.63l1.94-.66a5,5,0,0,1-.2,3,3,3,0,0,1-1.81,1.6,2.78,2.78,0,0,1-1,.16,10.42,10.42,0,0,1-1.23-.16,7,7,0,0,0-.91-.13,1.69,1.69,0,0,0-.67.08c-.61.22-.85.76-.72,1.63l-1.92.66a5.21,5.21,0,0,1,.21-3,2.93,2.93,0,0,1,1.79-1.58A2.81,2.81,0,0,1,100.59,167.6Z'
						/>
						<path
							className={`fillWhite peezzy-eye-s peezzy-eye-s-left`}
							d='M83.2,157.1l1.7,1.5l-2.4,1.9l3.1,0l-0.4,2.3l-2.9-1.1l1.5,2.7l-2.2,0.7l-0.5-3l-1.6,2.7l-1.8-1.6l2.4-1.9   l-3,0l0.4-2.3l2.9,1l-1.5-2.7l2.2-0.8l0.5,3.1L83.2,157.1z'
						/>
						<path
							className={`fillWhite peezzy-eye-s peezzy-eye-s-right`}
							d='M114.1,146.5l1.7,1.5l-2.4,1.9l3.1,0l-0.4,2.3l-2.9-1.1l1.5,2.7l-2.2,0.7l-0.5-3.1l-1.6,2.7l-1.8-1.6l2.4-1.9   l-3,0l0.4-2.3l2.9,1l-1.5-2.7l2.2-0.7l0.5,3.1L114.1,146.5z'
						/>
					</g>
				</g>
			</svg>
		</PeezzyStyle>
	);
});

export default Peezzy;
