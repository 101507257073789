import styled from 'styled-components';

const ZiggyStyle = styled.div`
	&.ziggy {
		mix-blend-mode: difference;

		> div {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			overflow: visible !important;
			transform: translate3d(var(--containerPaddingLR), 30%, 0);

			svg {
				overflow: visible !important;
			}
		}
	}
`;

export default ZiggyStyle;
