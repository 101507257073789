import { memo as Memo, useRef, useEffect, useCallback, useMemo } from 'react';
import { gsap } from 'gsap';

//* HOC's
import withUIContext from '@context/consumerHOC/UIConsumer';

//* Helpers
import breakString from '@helpers/breakString';

//* Components
import Ziggy from '@components/global/SvgComponents/Ziggy';
import Sharpy from '@components/global/SvgComponents/Sharpy';
import Rolly from '@components/global/SvgComponents/Rolly';
import Peezzy from '@components/global/SvgComponents/Peezzy';

//* Styles
import PageTopSectionStyle from './style';

const PageTopSection = Memo(({ className, innerTitle, ...props }) => {
	const AnimationComponent = useMemo(() => {
		switch (props.serviceType) {
			case 'Branding':
				return Peezzy;
			case 'E-commerce':
				return Ziggy;
			case 'Digital':
				return Sharpy;
			case 'Product':
				return Rolly;
			default:
				return Peezzy;
		}
	}, [props.serviceType]);

	//! Refs
	const topRef = useRef();
	const topRef1 = useRef();
	const topRef2 = useRef();
	const topRef3 = useRef();
	const topRef5 = useRef();

	//! Animation
	const tl = useMemo(() => gsap.timeline({ delay: 0.6 }), []);

	useEffect(() => {
		let arr = [topRef1.current?.children, topRef5.current?.children[0], topRef5.current?.children[1].children, topRef2.current, topRef3.current];
		let arr1 = [topRef1.current?.children[1], topRef5.current?.children[1].children[1]];

		arr = arr.filter((el) => {
			return el != null;
		});
		arr1 = arr1.filter((el) => {
			return el != null;
		});

		tl.staggerTo(
			[arr],
			0,
			{
				opacity: 1,
			},
			0.1
		).set(
			[arr1],
			{
				delay: 0.3,
				css: { fontStyle: 'italic' },
			},
			0.1
		);
	}, []);

	const sectionAnimation = useCallback(() => {
		if (topRef.current) {
			const elTop = topRef?.current?.getBoundingClientRect().top;

			if (elTop < props.winHeight) {
				tl.play();
			} else if (elTop >= props.winHeight) {
				tl.reverse();
			}
		}
	}, [topRef]);

	useEffect(() => {
		window.addEventListener('scroll', sectionAnimation);

		return () => {
			window.removeEventListener('scroll', sectionAnimation);
		};
	}, [sectionAnimation]);

	return (
		<PageTopSectionStyle
			ref={topRef}
			className={`ContainerWrap ${props.settings?.min_title ? '' : 'contMarg'} ${className || ''} ${props.settings?.underline ? 'underline' : ' '}`}>
			{props.title && !innerTitle && (
				<h2
					ref={topRef1}
					className={`${props.settings?.min_title ? 'h2' : 'h1'} font-poppins font-semi-bold title`}>
					{breakString(props.title)}
				</h2>
			)}

			{innerTitle && (
				<div className={'servicesTop'}>
					<div className={'servicesAnimationWrap'}>
						<AnimationComponent />
					</div>

					<div
						ref={topRef5}
						className={'servicesTitle'}>
						<p className={`h4 font-anonymous font-bold servicesCounter`}>{'0' + innerTitle}</p>

						<h2 className={`h1 font-poppins font-semi-bold titleOther`}>{breakString(props.title)}</h2>
					</div>
				</div>
			)}

			{props.subtitle && (
				<p
					ref={topRef2}
					className={`h5 font-poppins font-light subtitle`}>
					{props.subtitle}
				</p>
			)}

			{props.description && (
				<div className={'position_right'}>
					<p
						ref={topRef3}
						className={`${props.settings?.short_size ? 'short' : 'long'} p-m font-poppins font-light description`}>
						{props.description}
					</p>
				</div>
			)}
		</PageTopSectionStyle>
	);
});

export default withUIContext(PageTopSection, ['winHeight']);
