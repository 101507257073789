const breakString = (str, hasSpan = true, symb, className = '', emptyString) => {
	let arr = [];

	if (emptyString) {
		arr = str.split('');
	} else {
		arr = str.split(symb || '</br>');
	}

	if (!hasSpan) {
		return arr;
	}

	return arr.map((i, k) => (
		<span
			key={k}
			className={`${className || 'item'}-${k + 1}`}>
			{i}
		</span>
	));
};

export default breakString;
